<template>
  <div class="line_content">
    <div class="project_item_title">
      <div class="flex_content">
        <div class="project_item_text_1">{{ projectItem.projectDepartment }}</div>
        <a-icon type="edit" class="project_item_icon" @click="$emit('handleOpenProjectDepartment', projectItem)" v-perms="'update-project'"/>
      </div>
      <div class>{{ projectItem.createTime }}</div>
    </div>
    <div class="project_item_content">
      <template v-for="(row, index) in projectList">
        <template v-if="showItem(index)">
          <div :key="row.id" :class="getItemCls(row)">
            <div class="project_item_navs">
              <div class="item_content">
                <div class="project_title">{{ row.projectName }}</div>
              </div>
              <div class="item_content">
                <div class="flex_content">
                  <div class="label">项目负责人：</div>
                  <div class="project_item_text">{{ row.projectLeaderName }}</div>
                </div>
              </div>
              <div class="item_content">
                <div class="flex_content">
                  <div class="label">负责人账号：</div>
                  <div class="project_item_text">{{ row.username }}</div>
                </div>
              </div>
              <div class="item_content">
                <div class="flex_content">
                  <div class="label">项目起止时间：</div>
                  <div class="project_item_text">{{ formatTime(row) }}</div>
                </div>
              </div>
              <div class="btn-box">
                <div class="btn-box-item">
                  <a-button type="link" class="btn" @click="$emit('handleEdit', row)" v-perms="'update'">编辑项目</a-button>
                </div>
                <div class="btn-box-item">
                  <a-button type="link" class="btn" @click="$emit('handleOpenUser', row)" v-perms="'user'">项目人员</a-button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>

      <div class="btn-bot-box">
        <div class="col-box-box" v-if="projectList.length > 2 || isAllNot">
          <div class="tips" v-if="isAllNot">项目已全部过期</div>
          <div class="collapse-btn" @click="collapse = !collapse">
            <div class="label">{{ collapse ? '展开' : '折叠' }}</div>
            <a-icon :type="collapse ? 'down' : 'up'" style="color: #1890ff" />
          </div>
        </div>
        <div class="add_button" @click="$emit('handleOpen', projectItem)" v-perms="'add'">添加项目</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ProjectItem',
  props: {
    projectItem: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    projectItem: {
      immediate: true,
      deep: true,
      handler (value) {
        this.projectList = this.getSortList([...value.projectList])
        this.isAllNot = this.getIsNot(value.projectList)
      }
    }
  },
  data () {
    return {
      // 折叠状态
      collapse: true,
      projectList: [],
      isAllNot: false
    }
  },
  methods: {
    formatTime (row) {
      const fn = (time) => time && moment(time).format('YYYY/MM/DD')
      return `${fn(row.projectStartTime)} - ${fn(row.projectEndTime)}`
    },
    getItemCls (row) {
      return {
        project_item: true,
        [`project_item_${row.isActive ? 'active' : 'not'}`]: true
      }
    },
    getSortList (list) {
      if (Array.isArray(list)) {
        return list
          .map((item) => ({
            ...item,
            isActive: moment(item.projectEndTime).isAfter(moment())
          }))
          .sort((a, b) => {
            // if (a.isActive && !b.isActive) {
            //   return -1
            // }
            // if (b.isActive && !a.isActive) {
            //   return 1
            // }
            return moment(b.projectEndTime).format('x') - moment(a.projectEndTime).format('x')
          })
      }
      return []
    },
    showItem (index) {
      if (this.collapse) {
        if (this.isAllNot) {
          return false
        } else {
          return index < 3
        }
      } else {
        return true
      }
    },
    getIsNot (list = []) {
      if (list.length) {
        return !list.some((item) => moment(item.projectEndTime).isAfter(moment()))
      }
      return false
    }
  }
}
</script>

<style lang="less">
@import url('./index');
</style>
